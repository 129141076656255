import locationService from '../../../../services/notisphere/location'
import LocationModal from '../LocationModal'


export default {
    props: {
        providerOrgId: { type: Number, default: null }
    },
    components: {
        LocationModal
    },
    data() {
        return {
            total: 0,
            items: [],
            userIds: [],
            Listitems: [],
            centralizedsetting: false,
            search: {
                supplier_id: null,
                state: null,
                city: null,
                verified: null,
                notcovered: null
            },
            searchQuery: {},
            header: [
                {
                    label: '',
                    name: 'select',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'pl-2'
                },
                {
                    label: 'Supplier',
                    name: 'supplier_org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Id',
                    name: 'location_id',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Name',
                    name: 'location_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Address',
                    name: 'address1',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                },
                {
                    label: 'Last Update',
                    name: 'last_change_at',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right'
                }
            ],
            allChecked: null,
            isEnable: false,
            verifyLocation: [],
            terms: false,
            showE: false,
            showV: true,
            isCheckAll: false,
            locationModalKey: 0,
            cnt: 0,
            listSupplier: [],
            listCity: [],
            listState: [],
            listStatus: [{ text: 'Covered', value: 'Covered' },{ text: 'Invalid', value: 'Invalid' },
                { text: 'Not Covered', value: 'NotCovered' }, { text: 'Pending', value: 'Pending' }],
            CentralizedDataStatus: [{ text: 'Emailed', value: 'Emailed' },{ text: 'Invalid', value: 'Invalid' },
                { text: 'Not Covered', value: 'NotCovered' }, { text: 'Pending', value: 'Pending' }]
        }

    },
    mounted() {
        this.getSearchMeta()
    },
    computed: {
        getlistdata(){
            return this.centralizedsetting == true ? this.CentralizedDataStatus : this.listStatus
        }
    },
    methods: {
        getSearchMeta() {
            locationService.pro_getSearchMeta(this.providerOrgId).then(resp => {
                if (!resp.error) {
                    var data = resp.data.d
                    this.listSupplier = [
                        ...data.supplier
                    ]
                    this.listCity = [
                        ...data.city.map(x => { return { text: x, value: x } })
                    ]
                    this.listState = [
                        ...data.state.map(x => { return { text: x, value: x } })
                    ]
                }
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            this.fetchData()
        },
        DisableChange: function () {
            return !this.terms
        },
        addMoreClick() {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderInsert()
            })
        },
        updateLocation(payload) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderUpdate(payload)
            })
        },

        checkboxChange(item, event) {
            if (event.target.checked) {
                this.Listitems.push(item)
            }
            else {

                this.Listitems.forEach(item1 => {
                    if (item1.pkey == item.pkey) {
                        this.Listitems.splice(this.Listitems.indexOf(item1), 1)
                    }
                })

            }
            if (this.items.length == this.Listitems.length) {
                this.isCheckAll = true
            }
            else {
                this.isCheckAll = false
            }
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = true
            }
            if (this.Listitems.length == 0) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
            }
        },
        checkboxAllChange() {
            this.userIds = []
            this.verifyLocation = []
            this.Listitems = []
            if (this.isCheckAll)
                this.items.forEach(item => {
                    this.Listitems.push(item)
                    this.userIds.push(item.pkey.toString())
                })
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = true
            }
            if (this.Listitems.length == 0) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
            }
        },
        VerifyAllChange() {
            this.verifyLocation = []
            this.Listitems.forEach(item => {
                this.verifyLocation.push({site_id: item.site_id,supplier_add1: item.address1,supplier_add2: item.address2,supplier_city: item.city,supplier_state: item.state,supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: true, invalid: false , notcovered: true})
            })
            this.verifyLocation.forEach(item => {
                item.verify = true
                item.invalid = false
                item.notcovered = false
            })
            this.updateVerifyLocation()
            this.Listitems = []
            this.verifyLocation = []
            this.isCheckAll = false
            this.isEnable = false
            this.$refs.table.fetchDelay()
        },
        EmailedAllChange() {
            this.verifyLocation = []
            this.Listitems.forEach(item => {
                this.verifyLocation.push({site_id: item.site_id,supplier_add1: item.address1,supplier_add2: item.address2,supplier_city: item.city,supplier_state: item.state,supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: false, emailed: true, invalid: false , notcovered: true})
            })
            this.verifyLocation.forEach(item => {
                item.emailed = true
                item.verify = false
                item.invalid = false
                item.notcovered = false
            })
            this.updateVerifyLocation()
            this.Listitems = []
            this.verifyLocation = []
            this.isCheckAll = false
            this.isEnable = false
            this.$refs.table.fetchDelay()
        },

        NotCoveredAllChange() {
            this.verifyLocation = []
            this.Listitems.forEach(item => {
                this.verifyLocation.push({site_id: item.site_id, supplier_add1: item.address1,supplier_add2: item.address2,supplier_city: item.city,supplier_state: item.state,supplier_zip_code: item.zip_code,supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: false, invalid: false, notcovered: true   })
            })
            this.verifyLocation.forEach(item => {
                item.verify= false
                item.invalid= false
                item.notcovered = true
            })
            this.updateVerifyLocation()
            this.Listitems = []
            this.verifyLocation = []
            this.isEnable=false
            this.$refs.table.fetchDelay()
        },
        InvalidAllChange() {
            this.verifyLocation = []
            this.Listitems.forEach(item => {
                this.verifyLocation.push({site_id: item.site_id,supplier_add1: item.address1,supplier_add2: item.address2,supplier_city: item.city,supplier_state: item.state,supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: true, invalid: false , notcovered: true})
            })
            this.verifyLocation.forEach(item => {
                item.invalid = true
                item.verify = false
                item.notcovered = false
            })
            this.updateVerifyLocation()
            this.Listitems = []
            this.verifyLocation = []
            this.isCheckAll = false
            this.isEnable = false
            this.$refs.table.fetchDelay()
        },
        getCentralizedSettingData(){
            locationService.getCentralizedIntakeData(this.providerOrgId).then( resp =>{
                this.centralizedsetting = resp.data.data
                if(this.centralizedsetting == true){
                    this.showE = true
                    this.showV = false
                }
            })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            this.isCheckAll = false
            if (this.isEnable == true) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
                this.Listitems = []
            }
            locationService.pro_findLocation({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }, this.providerOrgId).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                    this.items.forEach(item => {
                        item.pkey = this.cnt
                        this.cnt = this.cnt + 1
                        this.getCentralizedSettingData()
                    })
                }
            })
        },
        updateVerifyLocation() {
            locationService.pro_updateVerifyLocation(this.verifyLocation, this.providerOrgId).then(resp => {
                if (!resp.error) {
                    this.verifyLocation = []
                    this.onCompleted('UPDATE')
                }
                this.checkboxAllChange()
            })
        }
    }
}